* {
    font-family: sans-serif;
}

input {
    height: 27px;
    width: 300px;
}

input[type="number"] {
    width: 70px !important;
}

input[type="submit"] {
    background-color: #aaa;
    cursor: pointer;
    height: 40px;
}

input, textarea {
    margin: 0 10px;
    border: 1px solid #777;
    border-radius: 2px;
    padding: 0 5px;
}

textarea {
    margin: 0;
    height: 100px;
    width: 500px
}

p {
    display: inline;
    margin: 5px 0;
}

.input {
    margin: 5px 15px;
}

.App {
    display: flex;
    flex-direction: column;
}

.input {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.description {
    width: 100%;
    text-align: center;
}